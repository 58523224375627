import React from 'react'

function AboutUs() {
  return (
    <div>
      <div class="Nosotros">
<h3>CONOCE MAS SOBRE NOSOTROS</h3>
    <p> Bienvenidos a nuestra pagina web. Aqui encontraran nuestras promociones y descuentos en las mejores marcas de autos de alta gama. 
    </p>
</div>
    </div>
  )
}

export default AboutUs
